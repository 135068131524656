.bar__float {

    .container-fluid {
        flex-direction: row;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            left: 15px;
            right: 15px;
            background: rgba(0,0,0,.1); } } }

.bar__logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;

    @include wide;

    @include tablet;

    @include mobile; }

.bar-logo__img {
    width: 220px;
    transition-duration: $trds;

    @include laptopSmall; }

.header__search {
    width: 100%;
    padding: 0 20px;
    flex: 1 0;
    display: flex;
    justify-content: center;

    @include mobile {
        padding: 0 5px; } }

.search__form {
    width: 100%;
    position: relative;
    max-width: 540px; }

.search__input {
    border: 1px solid var(--color-black);
    border-radius: 15px;
    font-size: 16px;
    padding: 9px 15px;
    padding-right: 45px;
    min-width: 100px; }

.search__btn {
    width: 24px;
    height: 24px;
    border: none;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 6px;
    cursor: pointer;
    transition-duration: $trds;

    &:hover {
        opacity: .5; }

    svg, img {
        width: 24px;
        height: 24px; } }

.bar__buttons {
    display: flex; }

.bar__button {
    display: flex;
    align-items: center;
    white-space: nowrap; }

.bar__soc {
    margin-left: 25px;

    @include mobile {
        margin-left: 5px; } }

.bar-soc__link {
    display: block;

    svg, img {
        width: 32px;
        height: 32px; } }

.nav__float {
    position: sticky;
    top: 0;
    z-index: 5;
    background: #fff;
    transition-duration: $trds; }

.scroll {

    .nav__float {
        box-shadow: 0 10px 10px rgba(0,0,0,.15); } }

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px; }

.nav__menu {
    width: 40px;
    height: 40px;
    display: none;
    border-radius: 50%;
    transition-duration: $trds;

    @include tablet {
        width: 40px;
        height: 40px;
        display: flex; }

    &:hover {
        box-shadow: 0 0 5px rgba(0,0,0,.1); }

    &.open {

        svg {
            display: none; }

        &::after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            background: var(--color-green);
            border-radius: 50%; }

        &::before {
            content: '×';
            color: #fff;
            display: flex;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            position: absolute; } }

    svg {
        width: 40px;
        height: 40px;
        fill: #2277C9;
        transition-duration: $trds; } }

.nav__catalog-btn {
    display: flex;
    align-items: center;
    line-height: 100%;

    &:hover {

        svg, img {

            path {
                stroke: var(--color-green); } } }

    svg, img {
        width: 24px;
        height: 24px;
        margin-right: 5px;

        path {
            transition-duration: $trds; } } }

.nav__list {
    display: flex;

    @include tablet {
        display: none; } }

.nav__item {
    white-space: nowrap;
    position: relative;

    &::after {
        content: '';
        height: 20px;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        left: 0;
        width: 1px;
        background: rgba(0,0,0,.1); }

    &:first-child {

        &::after {
            display: none; }

        .nav__link {
            padding-left: 0; } }

    &:last-child {

        .nav__link {
            padding-right: 0; }

        @include wide {
            margin-left: 15px; } }

    &:hover {

        .nav__link {
            color: var(--color-green); } }

    &.parent {

        .nav__link {

            &::after {
                content: '›';
                transform: rotate(90deg);
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2px;
                margin-left: 2px; } }

        &:hover {

            .child-nav__list {
                opacity: 1;
                visibility: visible;
                margin-top: 0; } } } }

.nav__link {
    font-size: 18px;
    text-decoration: none;
    color: var(--color-black);
    line-height: normal;
    transition-duration: $trds;
    padding: 11px 20px;
    display: flex;
    font-weight: 400;

    &:hover {
        color: var(--color-green); } }

.child-nav__list {
    position: absolute;
    left: 0;
    top: 100%;
    padding: 15px;
    display: flex;
    background: #fff;
    flex-direction: column;
    border-radius:  5px;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    background: #FFF;
    opacity: 0;
    visibility: hidden;
    margin-top: 15px;
    transition-duration: $trds;
    z-index: 5; }

.child-nav__item {}

.child-nav__link {
    font-size: 16px;
    text-decoration: none;
    color: var(--color-black);
    line-height: normal;
    transition-duration: $trds;
    display: flex;
    padding-top: 4px;
    padding-bottom: 4px;

    &:hover {
        color: var(--color-blue); } }




.nav__mobile {
    background: #fff;
    padding: 40px;
    position: fixed;
    z-index: 10;
    top: 100%;
    position: absolute;
    right: 0;
    width: 100%;
    transition-duration: $trds;
    margin-top: 50px;
    opacity: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 5;
    visibility: hidden;

    @include tablet;

    @include mobile {
        width: 100%; }

    &.open {
        transform: translateX(0);
        opacity: 1;
        margin-top: 0;
        visibility: visible; } }


.nav-mobile__list {
    display: flex;
    flex-direction: column;
    align-items: center; }

.nav-mobile__item {

    & + .nav-mobile__item {
        margin-top: 24px; }

    &.nav-mobile__item--order {

        .nav-mobile__link {
            position: relative;

            &:hover {
                color: var(--color-yellow);

                &::after {
                    background: var(--color-black); } }

            &::after {
                content: '';
                position: absolute;
                height: 6px;
                bottom: -3px;
                width: 100%;
                right: 0;
                background: var(--color-yellow);
                transition-duration: $trds;

                @include tablet {
                    height: 3px; }

                @include mobile {
                    height: 3px; } } } } }

.nav-mobile__link {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.44px;
    text-decoration: none;
    transition-duration: $trds;

    &:hover {
        color: var(--color-yellow); } }

.nav-mobile__info {
    font-size: 14px;
    font-weight: 300;
    line-height: normal;
    margin-top: 38px; }

