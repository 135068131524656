.page-index {

    .section__title {} }

.header {
    _margin-top: 10px; }

.header__slider {
    position: relative;

    .slick-prev {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 15px;
        background: url(../images/general/slider__left.svg) no-repeat #fff;
        z-index: 2;
        left: 20px;

        @include tablet {
            top: 100%;
            margin-top: 35px;
            left: 50%;
            margin-left: -54px; }

        &::before {
            display: none; } }

    .slick-next {
        width: 50px;
        height: 50px;
        background: #fff;
        border-radius: 15px;
        background: url(../images/general/slider__right.svg) no-repeat #fff;
        z-index: 2;
        right: 20px;

        @include tablet {
            top: 100%;
            margin-top: 35px;
            right: 50%;
            margin-right: -54px; }

        &::before {
            display: none; } }

    .slick-dots {
        position: absolute;
        top: 30px;
        right: 110px;
        width: auto;
        bottom: auto;
        display: flex;

        @include tablet {
            right: 25px;
            top: 25px; }

        li {
            height: 15px;
            height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;

            &.slick-active {

                button {
                    background: transparent;
                    border: 2px solid #fff; } }

            & + li {
                margin-left: 5px; }

            button {
                width: 10px;
                height: 10px;
                background: #fff;
                border-radius: 50%;

                &::before {
                    display: none; } } } } }

.slider__item {
    height: 650px;
    background: url(../images/content/index/slider__bg.svg) no-repeat 50% 50%;
    background-size: cover;
    color: #fff;
    overflow: hidden;
    position: relative;

    @include tablet {
        height: auto; }

    .row {
        display: flex;
        align-items: center;
        height: 100%; } }

.slider__info {

    @include tablet {
        padding-top: 25px;
        padding-bottom: 25px;
        z-index: 2;
        position: relative;
        padding-bottom: 100px; } }

.slider__label {
    display: block;
    line-height: normal;
    margin-bottom: 20px;

    span {
        background: #44D238;
        font-size: 16px;
        color: #fff;
        padding: 15px;
        position: relative;
        display: inline-block;

        &::after {
            content: '';
            position: absolute;
            width: 18px;
            top: 0;
            bottom: 0;
            right: -18px;
            background: url(../images/content/index/slider__tr.svg) no-repeat; }

        &:before {
            content: '';
            background: #44D238;
            position: absolute;
            left: -250px;
            top: 0;
            bottom: 0;
            right: 100%; } } }

.slider__img-wrap {

    @include tablet {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .2; } }

.slider__img {

    @include tablet {
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: 50% 50%; } }

.slider__title {
    font-size: 48px;
    font-weight: 700;
    font-size: calc(min(48px,3.95vw));

    @include tablet {
        font-size: 32px; } }

.slider__btn {
    margin-top: 25px; }

.slider__mps {
    display: flex;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%); }

.slider__mp {

    & + .slider__mp {
        margin-left: 20px; } }

.popular__item {
    margin-bottom: 20px;

    &:first-child {

        .popular__img {
            margin-right: 15px;
            margin-bottom: 15px;
            width: 220px; } } }

.popular__link {
    background: #EBEBEB;
    display: flex;
    aspect-ratio: 1/1;
    text-decoration: none;
    flex-direction: column;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    transition-duration: .5s;

    &:hover {
        box-shadow: 0 0 20px rgba(0,0,0,.25); } }

.popular__title {
    min-height: 80px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 600;
    align-self: flex-start;
    position: relative;
    line-height: 100%;
    font-size: calc(min(28px, 1.75vw));

    @include tablet {
        min-height: 0;
        font-size: 22px;
        padding-top: 25px;
        padding-bottom: 25px; }

    &::after {
        content: '';
        width: 150px;
        height: 1px;
        background: #000;
        position: absolute;
        left: 0;
        bottom: 0; } }

.popular__img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 290px;
    margin-top: 13px;
    align-self: flex-end;
    justify-self: flex-end; }

.popular__more {
    background: #30BE24;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .popular__title {
        color: #fff;
        height: auto;
        min-height: 0;
        text-align: center;
        align-self: center;

        &::after {
            display: none; } } }

.why__btn {
    margin-top: 40px;

    @include mobile {
        margin-top: 20px;
        margin-bottom: 40px; } }


.why__list {

    & > div {
        padding-bottom: 20px; } }

.why__item {
    background: #EBEBEB;
    border-radius: 20px;
    padding: 5px 20px 15px;
    height: 100%;
    position: relative; }

.why__label {
    right: 36px;
    top: 0;
    background: var(--color-green);
    width: 35px;
    border-radius: 0 0 50% 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 15px 5px 5px 5px; }

.why__desc {
    font-size: 16px;
    padding-top: 5px;
    position: relative;
    margin-top: 5px;

    &::after {
        content: '';
        width: 150px;
        height: 1px;
        background: var(--color-black);
        position: absolute;
        top: 0;
        left: 0; } }

.section__team {
    padding-top: 0;
    padding-bottom: 0;

    &::before {
        content: '';
        display: block;
        height: 162px;
        background: url(../images/content/index/team__line.svg) no-repeat 50% 0%;
        width: 100%;

        @include tablet {
            display: none; } } }

.team__bg {
    background: #EBEBEB;

    @include tablet {
        padding-top: 35px; } }

.team__desc {
    margin-bottom: 30px; }

.team__img-wrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: 100%; }

.team-img__desc {
    background: var(--color-green);
    color: var(--color-white);
    padding: 10px 50px;
    border-radius: 30px;
    text-align: center;
    position: absolute;
    bottom: 80px;
    left: 50%;
    width: 100%;
    max-width: 370px;

    @include wideBig {
        _margin-left: -185px;
        transform: translateX(-50%); } }

.section__contacts {
    padding-bottom: 0; }

.contacts__title {
    font-size: 18px;
    opacity: .5;
    margin-bottom: 5px; }

.contacts__list {
    margin-top: 30px; }

.contacts__item {
    line-height: normal;

    & + .contacts__item {
        margin-top: 15px; } }

.contacts__form {
    margin-top: 50px;
    position: relative;

    @include mobile {
        margin-bottom: 30px; } }

.form__title {
    font-size: 28px;
    margin-bottom: 30px;
    line-height: 100%;
    font-weight: 600; }

.form__label {
    display: block;

    & + .form__label {
        margin-top: 15px; } }

.form__input {
    padding: 10px;
    border: none;
    border-bottom: 1px solid #000;

    &.error {
        border-color: #ff0000; } }

.form__btn {
    margin-top: 20px; }

.form__success {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    opacity: 0;
    visibility: hidden;
    transition-duration: $trds;

    &.open {
        opacity: 1;
        visibility: visible; }

    p {
        margin-bottom: 20px; }

    svg, img {
        width: 100px;
        height: 100px; } }

.contacts__map {

    @include mobile {
        margin-left: -10px; } }

#map {
    height: 100%;
    width: 100%;
    min-height: 800px; }






// .section__about
//     padding: 150px 0

//     .section__title
//         text-align: right
//         margin-bottom: 20px

//         &.section__title--line

//             &::after
//                 right: 0
//                 transform: none
//                 left: auto

// .about__wrap
//     margin: 0 -20px
//     display: flex

// .about__desc
//     width: calc(100% / 12 * 6)
//     padding: 0 20px
//     display: flex
//     flex-direction: column
//     align-items: flex-end

// .about__text
//     width: calc(100% / 12 * 5)
//     padding: 0 20px

// .about__list
//     display: flex
//     justify-content: space-between

// .about__item
//     width: calc(33.33% - 20px)
//     background: #F7F7F7
//     border-radius: 10px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     padding: 15px
//     position: relative
//     min-height: 345px
//     display: flex
//     flex-direction: column
//     align-items: flex-end
//     text-align: right
//     justify-content: space-between

// .about-item__img
//     position: absolute
//     bottom: 0
//     right: 0

// .about-item__link
//     position: relative
//     z-index: 2
//     font-weight: 600
//     font-size: 20px
//     letter-spacing: 0.02em
//     text-transform: uppercase
//     color: var(--color-orange)
//     text-decoration: none
//     display: flex
//     align-items: center
//     width: 100%

//     svg
//         width: 40px
//         height: 10px

// .about-item__title
//     font-weight: 600
//     font-size: 28px
//     line-height: 115%
//     letter-spacing: 0.02em
//     position: relative
//     z-index: 2

// .about-item__desc
//     margin-top: auto
//     margin-bottom: 117px
//     position: relative
//     z-index: 2

// .section__quest
//     background: url(../images/content/quest/bg.jpg) no-repeat 50% 50%
//     background-size: cover
//     color: #fff
//     padding-top: 50px
//     padding-bottom: 50px

//     .container
//         display: flex
//         flex-direction: column
//         align-items: center

//     .section__title
//         color: #fff
//         margin-bottom: 0

// .quest__button
//     margin-top: 30px



// .section__objects
//     padding-top: 100px
//     padding-bottom: 100px

//     .slick-list
//         padding-bottom: 50px
//         padding-top: 10px

// .object__item
//     width: 520px
//     margin-right: 40px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     background: #f7f7f7
//     border: 1px solid rgba(0, 0, 0, 0.05)
//     border-radius: 20px
//     overflow: hidden
//     position: relative
//     text-decoration: none
//     color: var(--color-black)

// .object__img
//     display: block
//     width: 100%

// .object__info
//     background: #f7f7f7
//     border-radius: 15px
//     padding: 15px
//     position: absolute
//     bottom: 0
//     left: 0
//     right: 0

// .object__title
//     font-weight: 600
//     font-size: 24px
//     line-height: 115%
//     position: relative
//     color: var(--color-black)
//     padding-bottom: 10px
//     margin-bottom: 10px

//     &::after
//         content: ''
//         width: 150px
//         height: 1px
//         background: #000
//         position: absolute
//         bottom: 0
//         left: 0

// .object__desc

// .section__spec
//     padding-top: 140px
//     padding-bottom: 100px
//     background: #f7f7f7

//     .slick-list
//         padding-bottom: 50px
//         padding-top: 10px

//     .section__title
//         text-align: left

//         &:after
//             left: 0
//             transform: none

// .spec__item
//     width: 800px
//     margin-right: 20px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     background: #fff
//     border-radius: 20px
//     display: flex !important
//     padding: 30px
//     overflow: hidden
//     position: relative

// .spec__label
//     color: #fff
//     padding: 10px 20px
//     background: var(--color-orange)
//     font-weight: 600
//     font-size: 20px
//     line-height: 115%
//     border-radius: 60px
//     position: absolute
//     top: 30px
//     left: 30px
//     z-index: 3
//     text-transform: uppercase

// .spec__img-wrap
//     width: 50%
//     padding-right: 10px
//     display: flex
//     justify-content: center
//     position: relative
//     z-index: 2

// .spec__desc
//     width: 50%
//     padding-left: 10px
//     display: flex
//     flex-direction: column
//     justify-content: space-between
//     align-items: flex-start

// .spec__title
//     font-weight: 600
//     font-size: 28px
//     line-height: 115%
//     letter-spacing: 0.02em
//     padding-bottom: 20px
//     margin-bottom: 20px
//     position: relative

//     &::after
//         content: ''
//         position: absolute
//         bottom: 0
//         left: 0
//         width: 150px
//         background: #000
//         height: 1px

// .spec__intro
//     margin-bottom: 30px

// .spec__avail
//     margin-top: auto
//     margin-bottom: 30px
//     color: #fff
//     background: var(--color-blue)
//     font-weight: 600
//     font-size: 24px
//     line-height: 115%
//     padding: 10px 20px 10px 0
//     border-radius: 0 60px 60px 0
//     position: relative

//     &::before
//         content: ''
//         background: var(--color-blue)
//         position: absolute
//         top: 0
//         right: 100%
//         bottom: 0
//         left: -1000px

// .spec__link
//     position: relative
//     z-index: 2
//     font-weight: 600
//     font-size: 20px
//     letter-spacing: 0.02em
//     text-transform: uppercase
//     color: var(--color-orange)
//     text-decoration: none
//     display: flex
//     align-items: center
//     width: 100%

//     &:hover

//         svg
//             margin-left: 10px

//     svg
//         width: 40px
//         height: 10px
//         transition-duration: $trds

// .section__brands
//     background: #f7f7f7
//     padding-top: 75px
//     padding-bottom: 150px

//     .container
//         position: relative

//         &::after
//             content: ''
//             position: absolute
//             top: -100px
//             left: 20px
//             right: 20px
//             height: 1px
//             background: #000
//             opacity: .1

//     .section__title
//         text-align: left
//         margin-bottom: 20px

//         &:after
//             left: 0
//             transform: none

// .brands__wrap
//     display: flex
//     margin: 0 -20px

// .brands__desc
//     width: calc(100% / 12 * 4)
//     padding: 0 20px
//     display: flex
//     flex-direction: column

// .brands__list
//     width: calc(100% / 12 * 8)
//     padding: 0 20px
//     display: flex
//     justify-content: center
//     display: grid
//     gap: 40px
//     grid-template-columns: repeat(4, 1fr)

// .section__gallery
//     padding-top: 150px
//     padding-bottom: 100px

//     .section__title
//         margin-bottom: 60px

// .gallery__list
//     display: grid
//     gap: 40px
//     grid-template-columns: repeat(3, 1fr)

// .gallery__img
//     display: block
//     width: 100%

// .section__why
//     padding-top: 100px
//     padding-bottom: 150px

//     .section__title
//         text-align: left

//         &:after
//             left: 0
//             transform: none

// .why__list
//     display: grid
//     gap: 40px
//     grid-template-columns: repeat(3, 1fr)

// .why__item
//     border: 1px solid rgba(0, 0, 0, 0.05)
//     border-radius: 20px
//     padding: 20px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     background: #f7f7f7

// .why__title
//     font-style: italic
//     font-weight: 600
//     font-size: 24px
//     margin-bottom: 20px

// .section__sert
//     padding-top: 100px
//     padding-bottom: 150px

//     .section__title
//         text-align: left

//         &:after
//             left: 0
//             transform: none

// .sert__item
//     width: 380px
//     margin-right: 40px

// .sert__slider
//     position: relative

//     .slick-dots
//         width: 240px
//         height: 1px
//         background: #F7F7F7
//         left: 50%
//         transform: translateX(-50%)
//         display: flex
//         bottom: -55px

//         li
//             padding: 0
//             margin: 0
//             height: 2px
//             flex: 1 0

//         button
//             padding: 0
//             margin: 0
//             height: 2px
//             width: 100%

//             &::before
//                 display: none

//         .slick-active

//             button
//                 background: var(--color-orange)

// .sert-slider__wrap
//     position: relative

//     .slider-arrow__prev
//         width: 36px
//         height: 36px
//         border-radius: 50%
//         background: #f7f7f7
//         border: 1px solid rgba(0, 0, 0, 0.05)
//         display: flex
//         justify-content: center
//         align-items: center
//         transform: rotate(180deg)
//         margin-right: 150px
//         cursor: pointer

//         svg
//             width: 20px
//             height: 10px

//     .slider-arrow__next
//         width: 36px
//         height: 36px
//         border-radius: 50%
//         background: #f7f7f7
//         border: 1px solid rgba(0, 0, 0, 0.05)
//         display: flex
//         justify-content: center
//         align-items: center
//         margin-left: 150px
//         cursor: pointer

//         svg
//             width: 20px
//             height: 10px

// .sert-slider__arrow
//     position: absolute
//     bottom: -40px
//     left: 0
//     right: 0
//     display: flex
//     justify-content: center


// .section__faq

//     .container
//         flex-direction: row
//         justify-content: space-between

//     .section__title
//         font-size: 40px
//         text-align: left

// .faq__list-wrap
//     display: flex
//     border-radius: 20px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     background: #fff
//     padding: 30px
//     width: calc(100% / 12 * 8 - 20px)
//     justify-content: space-between

// .faq__form
//     display: flex
//     border-radius: 20px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0)
//     background: #fff
//     padding: 30px
//     width: calc(100% / 12 * 4 - 20px)
//     flex-direction: column
//     align-items: center

//     .section__title
//         text-align: center
//         width: 100%
//         margin-bottom: 27px
//         padding-bottom: 0
//         color: var(--color-blue)

// .faq__list
//     width: 100%
//     max-width: 680px
//     margin-left: 40px

// .faq__item
//     border-top: 1px solid #292929
//     padding-top: 15px
//     padding-bottom: 15px

//     &:first-child
//         border: none
//         padding-top: 0

//     &:last-child
//         padding-bottom: 0

// .faq__q
//     display: flex
//     justify-content: space-between
//     margin-right: 12px
//     font-weight: 600
//     font-size: 20px
//     line-height: 115%
//     cursor: pointer

//     svg, img
//         width: 24px
//         height: 24px
//         min-width: 24px
//         transition-duration: .5s

//     &.open

//         svg, img
//             transform: rotate(180deg)

// .faq__a
//     font-weight: 400
//     font-size: 18px
//     line-height: 115%
//     margin-top: 15px
//     display: none

// .faq-form__desc
//     margin-bottom: 30px
//     text-align: center
//     line-height: 115%

// .form__form
//     width: 100%

// .form__input
//     height: 40px
//     border: none
//     padding: 10px
//     border: 1px solid #292929
//     border-radius: 20px
//     color: #000
//     font-size: 20px

//     @include placeholder
//         color: #a8a8a8

//     &.error
//         border-color: #ff0000

// .form__textarea
//     height: 80px
//     border: none
//     padding: 10px
//     border: 1px solid #292929
//     border-radius: 20px
//     color: #000
//     font-size: 20px

//     @include placeholder
//         color: #a8a8a8

//     &.error
//         border-color: #ff0000

// .form__label
//     display: block
//     position: relative

// .form__label + .form__label
//     margin-top: 20px

// .form__btn
//     margin-top: 20px

// .section__contacts

// .contacts__maps
//     display: flex
//     justify-content: space-between

// .contacts__map
//     border-radius: 20px
//     box-shadow: 3px 2px 9px 0 rgba(0, 0, 0, 0.05), 13px 9px 15px 0 rgba(0, 0, 0, 0.04), 28px 20px 21px 0 rgba(0, 0, 0, 0.03), 50px 36px 25px 0 rgba(0, 0, 0, 0.01), 79px 56px 27px 0 rgba(0, 0, 0, 0);
//     width: calc(50% - 20px)
//     overflow: hidden
//     position: relative

//     &.contacts__map--store

//         .contacts-map__info
//             left: auto
//             right: 0
//             text-align: right

// .contacts-map__info
//     position: absolute
//     top: 0
//     left: 0
//     bottom: 0
//     display: flex
//     flex-direction: column
//     justify-content: space-between
//     z-index: 2
//     padding: 30px
//     pointer-events: none
//     width: 100%
//     max-width: 360px
//     font-size: 20px
//     line-height: 115%

// .contacts-map__title
//     font-weight: 600
//     font-size: 55px
//     line-height: 115%
//     color: #083570
//     margin-bottom: 30px

// .contacts-map__item

//     & + .contacts-map__item
//         margin-top: 20px

// #map__office
//     height: 400px

// #map__store
//     height: 400px

// [class*="ground-pane"]
//     filter: grayscale(1)
//     opacity: .25

// .ymaps-2-1-79-gototaxi, .ymaps-2-1-79-copyright, .ymaps-2-1-79-gototech, .ymaps-2-1-79-map-copyrights-promo, .ymaps-2-1-79-controls__control
//     display: none !important








// .section__services
//     padding-top: 100px
//     padding-bottom: 100px

//     @include laptop
//         padding-top: 50px
//         padding-bottom: 50px

// .services__list
//     display: flex
//     justify-content: center
//     flex-wrap: wrap
//     justify-content: center
//     margin: 0 -10px

// .services__item
//     width: calc(100% / 3 - 20px)
//     padding: 0 10px
//     margin-bottom: 30px

//     @include tablet
//         width: calc(100% / 2 - 20px)

//     @include mobile
//         width: 100%

// .services__link
//     text-decoration: none

// .servisec__img
//     display: block
//     width: 100%
//     margin-bottom: 15px

// .services__title
//     font-size: 21px
//     font-weight: 600

//     @include laptop
//         font-size: 18px

// .section__works
//     padding-top: 150px
//     padding-bottom: 100px

//     @include tablet
//         padding-top: 50px
//         padding-bottom: 50px

// .works__list
//     display: grid
//     grid-template-columns: repeat(4, 1fr)
//     gap: 20px

//     @include laptop
//         grid-template-columns: repeat(3, 1fr)

//     @include mobile
//         grid-template-columns: repeat(2, 1fr)

//     @include minMobile
//         grid-template-columns: repeat(1, 1fr)


// .works__img
//     display: block
//     width: 100%

// .works-item__desc
//     display: flex
//     height: 100%
//     align-items: flex-start
//     justify-content: center
//     flex-direction: column

//     .section__title
//         margin-bottom: 20px

// .section__year
//     background: var(--color-blue)
//     padding-top: 100px
//     padding-bottom: 100px

//     @include tablet
//         padding-top: 50px
//         padding-bottom: 50px

//     .section__title
//         color: var(--color-white)

// .year__list
//     display: grid
//     grid-template-columns: repeat(2, 1fr)
//     color: #fff
//     gap: 20px

//     @include mobile
//         grid-template-columns: repeat(1, 1fr)

// .year__item
//     border-radius: 20px
//     background: rgba(255,255,255,.3)
//     padding: 20px 30px

// .year-item__title
//     font-size: 28px
//     margin-bottom: 20px
//     font-weight: 700

//     @include tablet
//         font-size: 22px

// .year-item__desc
//     font-size: 16px


// .section__docs
//     background: #EAEFF3
//     padding-top: 100px
//     padding-bottom: 100px
//     text-align: center

//     @include tablet
//         padding-top: 50px
//         padding-bottom: 100px

// .docs__item
//     width: 280px
//     padding-right: 20px

// .docs__slider-wrap
//     position: relative
//     margin-top: 30px

// .docs-slider__arrow
//     position: absolute
//     bottom: -64px
//     left: 0
//     right: 0
//     height: 36px

// .docs-slider__arrow-left
//     position: absolute
//     left: 50%
//     margin-left: -173px
//     width: 36px
//     height: 36px
//     cursor: pointer

//     svg
//         width: 36px
//         height: 36px

// .docs-slider__arrow-right
//     position: absolute
//     left: 50%
//     margin-left: 147px
//     width: 36px
//     height: 36px
//     cursor: pointer

//     svg
//         width: 36px
//         height: 36px

// .docs__slider.slick-dotted.slick-slider
//     margin-bottom: 0

//     .slick-dots
//         display: flex
//         width: 260px
//         left: 50%
//         margin-left: -130px
//         bottom: -47px

//         li
//             height: 2px
//             margin: 0
//             flex: 1 0

//             &.slick-active
//                 background: var(--color-blue)

//             button
//                 width: 100%
//                 height: 2px
//                 padding: 0

//                 &:before
//                     display: none



