@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/libs";

 :root {
	--color-white: #FFFFFF;
	--color-gray: #313131;
	--color-black: #000000;
	--color-green: #30BE24; }


html {
	min-height: 100%;
	height: 100%; }

body {
	font: 400 20px/100% $base-font-family;
	color: var(--color-идфсл);
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	min-width: 300px;
	background: #fff;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include laptop {
		font: 400 16px/120% $base-font-family; }

	@include mobile {
		font: 400 16px/120% $base-font-family; }

	&.fixed {
		overflow: hidden; }

	&.scroll {

		.bar__float {
			opacity: 1;
			visibility: visible;
			box-shadow: 0 0 10px rgba(0,0,0,.1); } } }


*,
*::before,
*::after {
	box-sizing: border-box; }

ul,
ol {
  list-style: none;
  padding: 0; }

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0; }

a:not([class]) {
	text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

input,
button,
textarea,
select {
	font: inherit;
	width: 100%; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative;
	padding-top: 20px;
	padding-bottom: 20px; }

input,
select,
button {
	outline: none; }

a {
	color: var(--color-blue);

	&:hover {
		text-decoration: none; } }

p {
	margin-bottom: 15px; }


.btn {
	font-weight: 700;
	line-height: normal;
	color: var(--color-white);
	padding: 10px 15px 8px;
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	transition-duration: $trds;
	font-family: $base-font-family;
	cursor: pointer;
	justify-content: center;
	background: var(--color-green);
	border: 1px solid var(--color-green);
	white-space: nowrap;
	border-radius: 15px;
	line-height: normal;

	@include wide;

	@include tablet;

	@include mobile;

	&:hover {
		color: var(--color-green);
		background: var(--color-white); }

	&.btn--gray {
		color: var(--color-white);
		background: var(--color-gray);
		border-color: var(--color-gray);

		&:hover {
			color: var(--color-green); } }

	&.btn--transparenty {
		color: var(--color-green);
		background: var(--color-white);
		border-color: var(--color-green);

		&:hover {
			background: var(--color-green);
			color: var(--color-white); } }

	&.btn--white {
		color: var(--color-green);
		background: var(--color-white);
		border-color: var(--color-white);

		&:hover {
			background: transparent;
			color: var(--color-white); } } }

.input {
	border-radius: 4px;
	border: none;
	border-right: none;
	height: 50px;
	padding: 13px 30px;
	font-family: $base-font-family;

	@include laptop {
		height: 40px;
		padding: 10px 17px;
		font-size: 15px; }

	@include placeholder {
		color: #000; } }

.text--left {
	text-align: left !important; }

.text--right {
	text-align: right !important; }

.text--center {
	text-align: center !important; }

.color--green {
	color: var(--color-green); }

.color--blue {
	color: var(--color-blue); }

.color--white {
	color: var(--color-white); }

.color--gray {
	color: var(--color-gray); }

.hidden {
	display: none !important; }

.hidden--dekstop {
	display: none;

	@include tablet {
		display: block; } }

.hidden--tablet {
	display: block;

	@include tablet {
		display: none; } }

h1, .page--title, .h1 {
	font-weight: 700;
	font-size: 36px;
	line-height: normal;
	margin-bottom: 18px; }

h2, .h2 {
	font-size: 32px;
	margin-bottom: 30px;
	font-weight: 700; }

h3, .h3 {
	font-size: 18px;
	margin-bottom: 20px; }

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%; }

.content {}

.container-fluid {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;
	padding: 0 10px;
	flex-direction: column;
	display: flex; }

.section__title {
	line-height: normal;
	position: relative;
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 60px;
	margin-bottom: 30px;
	font-size: calc(min(40px,2.275vw));

	@include laptopSmall;

	@include tablet {
		margin-bottom: 30px;
		font-size: 28px; }

	@include mobile {
		margin-bottom: 20px; } }

.overlay {
	display: none;
	background: rgba(20, 22, 35, 0.15);
	position: fixed;
	z-index: 100;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0; }

.popup__thanks {
	background: var(--color-black);
	position: fixed;
	padding: 64px 113px;
	border-radius: 7px;
	z-index: 25;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -35%);
	width: calc(100% - 64px);
	max-width: 455px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: var(--color-white);
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	opacity: 0;
	visibility: hidden;

	&.open {
		opacity: 1;
		visibility: visible; }

	@include tablet {
		font-size: 18px; }

	@include mobile {
		max-width: 315px;
		font-size: 14px;
		padding: 56px 77px; }

	svg, img {
		width: 132px;
		height: 132px;

		@include tablet {
			width: 118px;
			height: 118px; }

		@include mobile {
			width: 94px;
			height: 94px; } } }

.popup {
	background: #fff;
	border-radius: 0px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) scale(0);
	_transform: translate(-50%,-50%);
	padding: 30px;
	z-index: 101;
	width: 80%;
	max-width: 380px;
	max-height: 90%;
	_opacity: 0;
	_visibility: hidden;
	box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.15);
	transition-duration: $trds;

	@include tablet {
		padding: 30px;
		width: 90%; }

	@include mobile {
		padding: 20px; }

	&.open {
		transform: translate(-50%,-50%) scale(1);
		opacity: 1;
		visibility: visible; } }

.popup__title {
	margin-bottom: 23px;
	font-size: 30px;
	line-height: mormal;
	font-weight: 700;
	line-height: 110%;
	text-align: center;

	@include tablet {
		font-size: 32px;
		padding-right: 20px; } }

.popup__desc {
	color: #676979;
	font-size: 16px;
	margin-bottom: 36px;

	@include tablet {
		margin-bottom: 20px;
		font-size: 14px;
		line-height: normal; } }

.popup__close {
	position: absolute;
	top: -30px;
	right: -30px;
	transition-duration: $trds;
	font-size: 36px;
	text-decoration: none;

	svg, img {
		display: block;
		width: 50px;
		height: 50px; }

	@include tablet {
		width: 20px;
		height: 20px;
		top: 17px;
		right: 17px;

		svg, img {
			display: block;
			width: 20px;
			height: 20px; } } }

.popup__label {
	display: block;
	margin-bottom: 15px; }

.popup__input {
	font-family: $base-font-family;
	font-size: 18px;
	line-height: 110%;
	padding: 15px;
	border: none;
	border-radius: 4px;
	background: #EFEFF0;
	border: 1px solid #EFEFF0;

	&.error {
		color: #000;
		border-color: #ff0000; }

	@include tablet {
		font-size: 16px; } }

.popup__btn-close {
	width: 100%; }

.popup__check {
	width: 62px;
	height: 62px;
	margin-bottom: 30px;

	svg {
		width: 62px;
		height: 62px; } }

#jGrowl button {
  width: auto; }

.jGrowl-notification .ui-state-highlight {
	color: #fff !important; }

body .mse2-ac-item__title {
	font-size: 14px !important; }

body .mse2-ac-item__price {
	font-size: 14px !important;
	font-weight: 700 !important; }

body .ui-menu .ui-menu-item-wrapper {
	padding: 2px 10px !important; }

body #ui-id-1 {
	max-height: 250px !important;
	overflow-y: auto !important;
	border: 1px solid #000 !important;
	border-radius: 15px !important; }

body .ui-state-active,
body .ui-widget-content .ui-state-active,
body .ui-widget-header .ui-state-active, a.ui-button:active,
body .ui-button:active, .ui-button.ui-state-active:hover {
	border: none !important;
	background: var(--color-green) !important;
	margin: 0 !important; }

.section__cart {

	.container-fluid {
		max-width: 1230px; }

	.ms-image {

		img {
			width: 70px; } }

	.ms-remove {

		.btn {
			width: 44px; } }

	// #msCart th.count,
	// #msCart th.weight,
	// #msCart th.price,
	// #msCart th.remove,
	// .ms-count,
	// .ms-weight,
	// .ms-price,
	// .ms-remove

	table {
		border-collapse: collapse;
		width: 100%; }

	#msCart th.remove, .ms-remove {
		width: 44px; }

	#msCart th.count, .ms-count {
		width: 44px; }

	.ms-header {
		background: var(--color-green);
		color: var(--color-white);

		th {
			padding: 10px 10px; } }

	td {
		padding: 10px;
		vertical-align: middle; }

	.table-responsive {
		width: 100%;
		overflow-x: auto;

		table {
			min-width: 600px; } }

	input[type=number] {
		border: 1px solid var(--color-black);
		border-radius: 15px;
		font-size: 16px;
		padding: 9px 15px;
		margin-right: 5px; } }

#msOrder {
	width: 100%;
	max-width: 600px;
	margin: 0 auto;

	.form-group {
		margin-top: 20px; }

	input {
		border: 1px solid var(--color-black);
		border-radius: 15px;
		font-size: 16px;
		padding: 9px 15px; }

	textarea {
		border: 1px solid var(--color-black);
		border-radius: 15px;
		font-size: 16px;
		padding: 9px 15px;
		height: 150px; } }

//Модули
@import "modules/modules";
