.page-inner {

    .section__title {
        padding: 0;
        margin: 0; } }

.section__breadcrumbs {
    font-size: 12px;
    padding: 0; }

.breadcrumbs__list {
    display: flex;
    white-space: nowrap;
    overflow-y: auto;

    @include tablet {
        padding-bottom: 10px; }

    @include scrollbars(6px, var(--color-green), #fff); }

.breadcrumbs__link {
    color: var(--color-green); }

.breadcrumbs__item {
    display: flex;
    align-items: center;

    &:last-child {

        &::after {
            display: none; } }

    &::after {
        content: '';
        width: 14px;
        height: 14px;
        display: block;
        background: url(../images/general/icon__bread.svg) no-repeat 50% 50%;
        background-size: 100% 100%; } }

.breadcrumbs__last {
    opacity: .5; }

.section__item {
    padding-bottom: 0;
    flex: 1 0; }

.inner__title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;

    @include tablet {
        margin-bottom: 30px; } }

.label__list {
    display: flex;
    margin-top: 22px; }

.label__item {
    font-weight: 600;
    font-size: 12px;
    line-height: 115%;
    border-radius: 60px;
    padding: 8px 15px;
    margin-left: 20px;
    text-transform: uppercase;

    &.label--action {
        background: var(--color-orange);
        color: #fff; } }

.item__gallery-wrap {

    @include tablet {
        margin-bottom: 20px; } }

.item-cart__price-wrap {

    @include tablet {
        margin-bottom: 20px; } }

.item__inner {
    display: flex;
    justify-content: space-between;
    min-width: 0;
    margin-bottom: 100px;

    @include tablet {
        margin-bottom: 40px; } }

.item__thumbs {
    min-width: 0;
    margin-top: -10px;
    position: relative;

    &::after {
        display: none;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); }


    .slick-next {
        right: auto;
        bottom: 15px;
        top: auto;
        color: #000;
        content: '';
        width: 32px;
        height: 32px;
        background: var(--color-green) url('data:image/svg+xml,<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="%23ffffff"></path></g></svg>') no-repeat 50% 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50%;
        left: 50%;
        transform: rotate(90deg) translateY(50%);

        &:before {
            display: none; } }

    .slick-prev {
        left: 15px;
        color: #000;
        content: '';
        width: 32px;
        height: 32px;
        background: var(--color-green) url('data:image/svg+xml,<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="%23ffffff"></path></g></svg>') no-repeat 50% 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50%;
        z-index: 2;
        left: 50%;
        top: 15px;
        transform: rotate(90deg) translateY(50%);

        &:before {
            display: none; } } }

.item__thumb {
    opacity: .5;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;

    &.slick-current {
        opacity: 1; } }

.item__galleries {
    min-width: 0;

    img {
        width: 100%;
        max-width: none; }

    .slick-next {
        right: 15px;
        color: #000;
        content: '';
        width: 45px;
        height: 45px;
        background: var(--color-green) url('data:image/svg+xml,<svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="%23ffffff"></path></g></svg>') no-repeat 50% 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50%;

        &:before {
            display: none; } }

    .slick-prev {
        left: 15px;
        color: #000;
        content: '';
        width: 45px;
        height: 45px;
        background: var(--color-green) url('data:image/svg+xml,<svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="%23ffffff"></path></g></svg>') no-repeat 50% 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 50%;
        z-index: 2;

        &:before {
            display: none; } } }

.item__desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

.item-desc__title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px; }

.item-desc__intro {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 30px; }

.item__cart-wrap {
    margin-top: auto; }

.item-cart__price {
    font-weight: 700;
    font-size: 28px;
    color: var(--color-green);
    line-height: normal; }

.item-cart__oldprice {
    font-size: 12px;
    color: #000;
    opacity: .5;
    margin-left: 10px;
    line-height: normal;
    margin-bottom: 4px; }

.item-cart__prices {
    display: flex;
    align-items: flex-end; }

.item-cart__delivery {
    background: #EBEBEB;
    font-size: 16px;
    padding: 20px 35px;
    text-align: center;
    border-radius: 100px; }

.item-cart__avai {
    font-size: 16px;
    margin-bottom: 6px; }

.item__form {
    display: flex;
    margin-top: 50px;
    align-items: flex-start;
    padding-top: 30px;
    border-top: 1px solid rgba(0,0,0,.1);

    @include wide {
        flex-direction: column; }

    @include tablet {
        margin-top: 30px; } }

.item-form__count {
    display: flex;
    width: 130px;
    margin-right: 40px;
    background: #EBEBEB;
    border-radius: 15px;

    @include wide {
        margin-right: 20px; }

    @include minMobile {
        margin-right: 0;
        width: 100%; } }

.form-count__min {
    display: flex;
    background: var(--color-green);
    color: var(--color-white);
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 115%;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    user-select: none; }

.form-count__pls {
    display: flex;
    background: var(--color-green);
    color: var(--color-white);
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 115%;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    user-select: none; }

.form-count__value {
    width: 50px;
    height: 44px;
    font-weight: 700;
    font-size: 24px;
    line-height: 115%;
    color: var(--color-green);
    border: none;
    background: transparent;
    text-align: center;

    @include minMobile {
        flex: 1 0; } }

.item-form__buttons {
    display: flex;
    flex: 1 0;
    flex-wrap: wrap;

    @include wide {
        margin-top: 20px; }

    @include minMobile {
        flex-direction: column;
        width: 100%; }

    .btn {
        flex: 1 0; }

    .btn + .btn {
        margin-left: 20px;

        @include minMobile {
            margin-left: 0; } } }

.item-form__oneclick {
    margin-bottom: 20px; }

.item-form__btn {
    margin-bottom: 20px;

    @include wide {
        width: 130px;
        flex: auto !important; }

    @include minMobile {
        width: 100%; } }

.section__tabs {
    padding-top: 0; }

.tab__border {
    font-size: 18px;
    text-align: center;
    color: var(--color-white);
    background: var(--color-green);
    border-radius: 100px;
    padding: 20px; }

.tab__desc {
    font-size: 16px;
    margin-bottom: 40px; }

.tab__video {
    position: relative;
    display: block;
    margin-bottom: 30px;

    &::after {
        content: '';
        width: 60px;
        height: 60px;
        background: url(../images/general/icon__play.svg) no-repeat;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -30px 0 0 -30px; } }

.tab-video__img {
    border-radius: 30px;
    width: 100%; }

.tab__title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: normal; }

.tab__table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 50px;

    @include mobile {
        grid-template-columns: repeat(1, 1fr); }

    ul {
        list-style: numbers;
        padding-left: 20px; } }

.tab-table__title {}

.tab-table__item {
    background: #EBEBEB;
    padding: 20px 20px; }

.tab-table__title {
    color: var(--color-green);
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;

    &::after {
        content: '';
        width: 150px;
        height: 1px;
        background: #000;
        position: absolute;
        left: 0;
        bottom: 0; } }

.tab-order__list {
    margin-bottom: 50px; }

.tab-order__item {
    position: relative;
    padding: 15px 15px 15px 60px;

    & + .tab-order__item {
        margin-top: 10px; }

    span {
        width: 50px;
        height: 50px;
        background: var(--color-green);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        font-size: 24px; } }

.tab__contacts {
    background: #EBEBEB;
    padding: 30px; }

.tab-contacts__title {
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 15px;
    position: relative;

    &::after {
        content: '';
        width: 150px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #000; } }

.tab-contacts__del {
    padding-top: 15px;
    margin-top: 15px;
    opacity: .5;
    position: relative;

    &::after {
        content: '';
        width: 150px;
        height: 1px;
        position: absolute;
        left: 0;
        top: 0;
        background: #000; } }

.tab-contacts__list {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column; } }

.tab-contacts__name {
    font-size: 16px;
    opacity: .5;
    margin-bottom: 5px; }

.tab-contacts__item {
    white-space: nowrap;
    padding-left: 20px;

    @include mobile {
        padding-left: 0;
        margin-top: 20px; }

    &:first-child {
        padding-left: 0;
        white-space: normal; } }

.item__tech {}

.item-tech__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    margin-bottom: 20px; }

.item-tech__list {}

.item-tech__item {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    background: url(../images/content/item/tech.png) repeat-x 0 100%;

    @include mobile {
        font-size: 16px; }

    & + .item-tech__item {
        margin-top: 5px; } }

.tech-item__name {
    position: relative;
    background: #fff;
    padding-right: 5px; }

.tech-item__value {
    font-weight: 600;
    text-align: right;
    position: relative;
    background: #fff;
    padding-left: 5px; }

.item__tabs-wrap {
    display: flex;
    justify-content: space-between; }

.item__tabs {
    width: 100%; }

.tabs__list {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-columns: repeat(3,1fr);
    gap: 20px;
    margin-bottom: 50px;

    @include tablet {
        grid-template-columns: repeat(2,1fr); }

    @include minMobile {
        grid-template-columns: repeat(1,1fr); } }

.tab__item {
    border-radius: 15px;
    padding: 13px 15px;
    border: 1px solid #000;
    font-size: 16px;
    text-decoration: none;
    color: #000;
    display: flex;
    justify-content: center;
    transition-duration: .5s;

    &.active, &:hover {
        background: var(--color-green);
        border-color: var(--color-green);
        color: #fff; } }

.tab__text {
    display: none;

    &.active {
        display: block; } }

.reviews__list {
    display: flex;
    flex-direction: column;

    @include tablet {
        margin-bottom: 20px; } }

.reviews__item {
    background: #EBEBEB;
    padding: 20px;

    & + .reviews__item {
        margin-top: 20px; } }

.reviews__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }

.reviews__title {
    color: var(--color-green);
    font-weight: 700; }

.reviews__date {
    font-size: 12px; }

.reviews__text {
    font-size: 16px; }

/****КАТЕГОРИЯ****/

.page-category {

    .inner__title {
        margin-bottom: 20px; } }

.section__category {
    flex: 1 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column; }

.category__wrap {
    flex: 1 0;
    display: flex;

    .container-fluid {
        display: flex;
        flex-direction: row;

        @include tablet {
            flex-direction: column; } } }


.category__nav {
    width: 370px;
    min-width: 370px;
    padding: 20px;
    padding-left: 0;
    margin-right: 20px;
    background: rgba(235,235,235,.3);
    background: #f9f9f9;
    border-radius: 0 30px 0 0;
    position: relative;

    &::before {
        content: '';
        background: rgba(235,235,235,.3);
        background: #f9f9f9;
        position: absolute;
        right: 100%;
        top: 0;
        bottom: 0;
        left: -5000px;

        @include tablet {
            left: -10px;
            right: -10px; } }


    @include wide {
        width: 270px;
        min-width: 270px; }

    @include tablet {
        width: 100%;
        border-radius: 0;
        margin-bottom: 20px;
        padding-right: 0; } }

.category__content {
    flex: 1 0;
    padding-bottom: 100px; }

.caregory__desc {
    font-size: 16px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0,0,0,.1); }

.category-nav__list {
    display: flex;
    flex-direction: column;
    position: relative; }

.category-nav__item {

    & + .category-nav__item {
        margin-top: 10px; } }

.category-nav__link {
    display: flex;
    padding: 15px 20px;
    font-size: 18px;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 15px;
    transition-duration: .5s;
    font-weight: 600;

    &:hover {
        background: var(--color-green);
        color: #fff;
        border-color: var(--color-green); } }

.category__filters-wrap {
    border: 1px solid #000;
    border-radius: 15px;
    padding: 25px 20px;
    padding-top: 40px;
    margin-top: 60px;
    position: relative; }

.category__filters-title {
    padding: 5px;
    font-size: 22px;
    font-weight: 600;
    background: #f9f9f9;
    position: absolute;
    top: -15px;
    left: 20px; }

.category-list__wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0,0,0,.1);

    @include mobile {
        grid-template-columns: repeat(2, 1fr); } }

.category-list__link {
    display: flex;
    background: #EBEBEB;
    min-height: 100px;
    align-items: center;
    font-size: 24px;
    text-decoration: none;
    padding: 20px 30px;
    position: relative;
    padding-right: 35%;
    line-height: normal;
    height: 100%;

    @include wide {
        font-size: 20px;
        min-height: 80px; }

    @include mobile {
        font-size: 16px;
        padding: 15px; }

    span {
        position: relative;
        z-index: 2; } }

.category-list__img {
    position: absolute;
    right: 0;
    height: 100%;

    @include mobile {
        opacity: 0.25; } }

.category-items__wrap {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    @include mobile {
        grid-template-columns: repeat(2, 1fr); } }

.item__link {
    text-decoration: none; }

.item__img {
    display: block;
    width: 100%;
    margin-bottom: 8px; }

.item__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;

    span {
        color: var(--color-green); } }

.item__intro {
    font-size: 14px;
    margin-bottom: 8px; }

.item__price {
    font-weight: 600; }

.section__content {
    flex: 1 0; }
