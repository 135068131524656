.footer {
    background: #313131;
    color: #fff;

    .row > div {
        padding-bottom: 20px; } }

.footer__nav {
    padding-top: 50px; }

.footer__item {

    & + .footer__item {
        margin-top: 5px; } }

.footer__link {
    font-size: 18px;
    text-decoration: none; }

.footer__title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px; }

.footer-contacts__title {
    font-size: 16px;
    opacity: .5;
    margin-bottom: 5px; }

.footer-contacts__value {
    font-size: 18px; }

.footer-contacts__list {
    margin-top: 30px; }

.footer-contacts__item {
    line-height: normal;

    & + .footer-contacts__item {
        margin-top: 15px; } }

.footer__copy {

    .container-fluid {
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 10px;
            right: 10px;
            top: 0;
            height: 1px;
            background: #fff; } } }

.soc__list {
    display: flex; }

.soc__link {
    display: block;

    & + .soc__link {
        margin-left: 15px; }

    svg, img {
        width: 36px;
        height: 36px; } }
