@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'VeranoSans';
	src: url('../fonts/VeranoSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'VeranoSans';
	src: url('../fonts/VeranoSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'VeranoSans';
	src: url('../fonts/VeranoSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}